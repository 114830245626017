import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, Image } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import axios from "axios"
import defaultImage from "../../../images/NoobsLogo.png"
import Test1 from "../../../images/Test-1.png"
import Test2 from "../../../images/Test-2.png"

type MyNoobsProps = {
  accountAddress: string | null,
  contract: Contract,
  isTestNet: boolean,
  getAndSetAccount: Function
}

const MyNoobs: React.FC<MyNoobsProps> = ({ accountAddress, contract, isTestNet, getAndSetAccount }) => {
  const [ownersNoobsMetadata, setOwnersNoobsMetadata] = useState<Object[]>([])
  const [contractAddress, setContractAddress] = useState<string>()

  useEffect(() => {
    if (accountAddress && contract) {
      contract.methods.listNoobsForOwner(accountAddress).call().then(async (ownersNoobs: number[]) => {
        const noobsMetadata = await Promise.all(ownersNoobs.map(async (tokenId) => {
          const tokenUri = await contract.methods.tokenURI(tokenId).call();
          if (tokenUri) {
            const { data: metaData } = await axios.get(tokenUri)
            return { ...metaData, tokenId }
          }
          return { tokenId }
        }))

        setOwnersNoobsMetadata(noobsMetadata);
        setContractAddress(contract.options.address);
      }).catch(e => {
        // TODO (Ben): Improve error handling
        console.log(e);
      })
    }
  }, [accountAddress, contract]);

  const noobCards = ownersNoobsMetadata.map((metadata, index) => {
    const { tokenId } = metadata
    // TODO (Ben): Use metadata to generate Opensea link instead of array index
    const openseaBaseUrl = isTestNet ? "https://testnets.opensea.io/assets/" : "https://opensea.io/assets/"
    const openseaUrl = openseaBaseUrl + contractAddress + "/" + tokenId
    const image = !!metadata && metadata["image"] ? metadata["image"] : defaultImage;
    // TODO (Ben): Use metadata to generate default candy title instead of array index
    const noobTitle = !!metadata && metadata["name"] ? metadata["name"] : "NOOB #" + tokenId;

    return (
      <Col key={index} className="mynoobs-card d-flex align-items-center justify-content-center">
        <a href={openseaUrl} target="_blank" className="mynoobs-card-link">
          <Card style={{ width: '15rem' }} className="no-border mynoobs-card-display">
            <Card.Img variant="top" src={image} className="mynoobs-image" />
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>{noobTitle}</Card.Title>
            </Card.Body>
          </Card>
        </a>
      </Col>
    );
  });

  const shortenedAddress = accountAddress ? accountAddress.substr(0, 6) + "..." + accountAddress.substr(accountAddress.length - 4) : "";

  return (
    <>
      <Container>
        <div className="centered mynoobs-content">
          <Row>
            <Col>
              <h1 className="mynoobs-header">NOOBS COLLECTION</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="mynoobs-sub-header">{noobCards.length} {noobCards.length > 1 ? "NOOBS belong" : "NOOB belongs"} to {shortenedAddress}</h4>
            </Col>
          </Row>
        </div>
        <Row className="d-flex align-items-center justify-content-center">
          {noobCards}
        </Row>
      </Container>
    </>
  )
}

export default MyNoobs
